import { defineComponent, h, getCurrentInstance } from 'vue'
import { ssrRenderSlotInner } from 'vue/server-renderer'
import type { Slots, ComponentInternalInstance } from 'vue'

/**
 * Check if something is a promise.
 *
 * Copied from vue/server-renderer.
 */
function isPromise(p: any) {
  if (typeof p === 'object' && typeof p.then === 'function') {
    return true
  }

  return false
}

/**
 * Unrolls (flattens) the buffer array.
 *
 * Copied from vue/server-renderer.
 */
async function unrollBuffer(buffer: any) {
  let ret = ''
  for (let i = 0; i < buffer.length; i++) {
    let item = buffer[i]
    if (isPromise(item)) {
      item = await item
    }
    if (typeof item === 'string') {
      ret += item
    } else {
      ret += await unrollBuffer(item)
    }
  }
  return ret
}

/**
 * Render the contents of a slot and return the markup.
 */
export function renderSlot(
  slots: Slots,
  parent: ComponentInternalInstance,
): Promise<string> {
  const buffer: any[] = []
  const push = (v: any) => {
    buffer.push(v)
  }

  ssrRenderSlotInner(slots, 'default', {}, null, push, parent)
  return unrollBuffer(buffer)
}

export default defineComponent({
  name: 'NuxtAsyncPage',

  async setup() {
    const slots = useSlots()
    if (!slots.default) {
      return () => h('div')
    }
    if (process.server) {
      const nuxtApp = useNuxtApp()
      // It's guaranteed to be always here.
      const parent = getCurrentInstance()!.parent!
      const result = await renderSlot(slots, parent)
      nuxtApp._pageRendered = true

      // TODO: Find a way to properly type this hook, as TS doesn't know it.
      // @ts-ignore
      await nuxtApp.callHook('page:rendered')

      return () =>
        h('div', {
          innerHTML: result,
        })
    }

    return () => h('div', slots.default!())
  },
})
